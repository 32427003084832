import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format, isDate } from 'date-fns';
import DateSelector from '../../components/DateSelector/DateSelector';
import FixtureGrid from '../../components/FixtureGrid/FixtureGrid';
import { usePaginatedRequest, useRequest } from '../../utils/general';
import { themeProps } from '../../utils/customPropTypes';
import DropdownSelector from '../../components/Dropdown/Dropdown';

const FixtureBrowser = ({
  apiKey,
  theme,
  buttonText,
  onFixtureClick,
  titleClasses,
  containerClasses,
  dateSelectorClasses,
  endpoint,
  enableSportFilter,
}) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState('home_allFixtures');
  const [selectedSport, setSelectedSport] = useState(null);
  const [, availableSports, fetchSports] = useRequest();

  const sportFilterEnabled = enableSportFilter && theme.show_sport_filter;

  const [data, loading, hasMorePages, fetchNextPage, loadingPage] = usePaginatedRequest(
    endpoint,
    8,
    {
      apiKey,
      localDate: !isDate(selectedDate) ? undefined : format(selectedDate, 'y-M-d'),
      localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      sportId: selectedSport || undefined,
    },
    [selectedDate, selectedSport],
  );

  useEffect(() => {
    if (sportFilterEnabled) {
      fetchSports('sports/available', {
        apiKey,
      }).catch(() => {});
    }
  }, [sportFilterEnabled, apiKey, fetchSports]);

  return (
    <div>
      <div className={`md:flex md:items-center ${containerClasses} pb-4 pt-8 md:pb-8`}>
        <p className={`flex-1 break-words ${titleClasses}`}>{t('home_upcomingFixtures')}</p>
        {sportFilterEnabled && availableSports?.length > 1 && (
          <div className="flex-1 px-6 pt-4 sm:px-0 md:max-w-sm md:pt-0">
            <DropdownSelector
              placeholder="Filter by sport"
              options={availableSports?.map?.(sport => ({ key: sport.id, value: sport.name }))}
              selected={availableSports?.find?.(s => s.id === selectedSport)?.name}
              onValueChange={v => setSelectedSport(v.key)}
            />
          </div>
        )}
      </div>
      <DateSelector
        selectedDate={selectedDate}
        onPressDate={d => setSelectedDate(d)}
        apiKey={apiKey}
        mainBrand={theme?.mainBrand}
        containerClasses={dateSelectorClasses}
      />
      <FixtureGrid
        apiKey={apiKey}
        loadMore={hasMorePages && fetchNextPage}
        loadingPage={loadingPage}
        loading={loading}
        fixtures={data}
        theme={theme}
        buttonText={buttonText}
        onFixtureClick={onFixtureClick}
        containerClasses={containerClasses}
      />
    </div>
  );
};

FixtureBrowser.propTypes = {
  apiKey: PropTypes.string.isRequired,
  theme: themeProps,
  buttonText: PropTypes.string.isRequired,
  onFixtureClick: PropTypes.func,
  titleClasses: PropTypes.string,
  containerClasses: PropTypes.string,
  dateSelectorClasses: PropTypes.string,
  endpoint: PropTypes.string,
  enableSportFilter: PropTypes.bool,
};

FixtureBrowser.defaultProps = {
  theme: undefined,
  endpoint: 'fixtures',
  onFixtureClick: () => {},
  titleClasses: '',
  containerClasses: 'containerA mx-auto',
  dateSelectorClasses: 'px-6 sm:containerA sm:mx-auto sm:px-0',
  enableSportFilter: false,
};

export default FixtureBrowser;
