import React from 'react';
import PropTypes from 'prop-types';

const OffsetBackground = ({ children, className, offset, innerClass, additionalStyle }) => (
  <div
    style={{ marginTop: offset, ...additionalStyle }}
    className={`inline-block w-full bg-lightGrey ${className}`}
    data-test-id="offset-background"
  >
    <div style={{ marginTop: `-${offset}px` }} className={innerClass}>
      {children}
    </div>
  </div>
);

OffsetBackground.propTypes = {
  offset: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  innerClass: PropTypes.string,
  additionalStyle: PropTypes.shape(),
};
OffsetBackground.defaultProps = {
  offset: 80,
  children: null,
  className: '',
  innerClass: '',
  additionalStyle: {},
};

export default OffsetBackground;
