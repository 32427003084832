import React from 'react';
import PropTypes from 'prop-types';
import Chevron from '../Icons/Chevron';
import styles from './styles.module.scss';

const DropdownSelector = ({ options, selected, onValueChange, placeholder, label }) => {
  if (!options?.length) {
    return null;
  }

  return (
    <div>
      {label && <p className="pb-2 font-bold">{label}</p>}
      <div className="relative">
        <select
          className={styles.dropdown}
          onChange={e => onValueChange(options.find(o => `${o.value}` === e.target.value))}
          value={selected || ''}
        >
          {placeholder && (
            <option default value="">
              {placeholder}
            </option>
          )}
          {options?.map(option => (
            <option key={option.key} id={option.key} value={option.value}>
              {option.value}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute right-4 top-1/2 -translate-y-1/2 transform">
          <Chevron className="w-5 rotate-180 text-black" />
        </div>
      </div>
    </div>
  );
};

const keyValue = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

DropdownSelector.propTypes = {
  options: PropTypes.arrayOf(keyValue).isRequired,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onValueChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

DropdownSelector.defaultProps = {
  selected: null,
  placeholder: null,
  label: null,
};

export default DropdownSelector;
