import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FanzoLogo from '../Logo/svg/FanzoLogo';
import { themeProps } from '../../utils/customPropTypes';

const Footer = ({ theme }) => {
  const { t } = useTranslation();

  return (
    <footer className="relative bg-black px-4 pb-2 text-white">
      <div className="flex items-center py-4 text-sm lg:justify-center lg:pb-6">
        <p>{t('powered_by')}</p>
        <Link
          to={{ pathname: theme?.powered_by_link || 'https://www.fanzo.com/' }}
          target="_blank"
          className="md:ml-8 lg:ml-3"
          data-test-id="footer_powered_by_link"
        >
          <FanzoLogo fill="white" height={50} />
        </Link>
      </div>
      <p className="text-xs lg:text-center">{t('footer_rights')}</p>
    </footer>
  );
};

Footer.propTypes = {
  theme: themeProps,
};

Footer.defaultProps = {
  theme: undefined,
};

export default Footer;
