import React from 'react';
import { useTranslation } from 'react-i18next';
import { themeProps } from '../../utils/customPropTypes';

const PageHeader = ({ theme }) => {
  const { t } = useTranslation();
  const { logo, title, sub_title: subTitle } = theme || {};

  if (!logo && !title && !subTitle) {
    return null;
  }

  return (
    <div className="lg:containerA flex flex-col items-center border-b border-b-veryLightGrey pt-14 text-center md:border-b-0 lg:mx-auto lg:flex-row lg:border-b lg:text-left">
      {logo && (
        <img
          data-test-id="page_header_logo"
          src={logo}
          alt={t('general_logo')}
          className="mb-6 w-32 justify-center lg:mb-0 lg:mr-6 lg:h-20 lg:w-auto"
        />
      )}
      <div className="border-b-veryLightGrey pb-8 md:border-b md:px-8 lg:border-b-0 lg:px-0">
        {title && (
          <h2 className="headingFont text-5xl uppercase" data-test-id="page_header_title">
            {title}
          </h2>
        )}
        {subTitle && (
          <p
            className="whitespace-pre-wrap pt-2 font-medium opacity-60"
            data-test-id="page_header_sub_title"
          >
            {subTitle}
          </p>
        )}
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  theme: themeProps,
};

PageHeader.defaultProps = {
  theme: undefined,
};

export default PageHeader;
