import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { eachDayOfInterval } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useRelativeLocaleDate } from '../../utils/date';
import './DateSelector.scss';
import Chevron from '../Icons/Chevron';
import { trackEvent, eventTypes } from '../../utils/tracking';

const DateSelector = ({ onPressDate, selectedDate, apiKey, mainBrand, containerClasses }) => {
  const { t } = useTranslation();
  const getRelativeLocaleDate = useRelativeLocaleDate();
  const [dates, setDates] = useState([]);
  const [leftDisabled, setLeftDisabled] = useState(true);
  const [rightDisabled, setRightDisabled] = useState(false);

  useEffect(() => {
    const end = new Date();
    end.setDate(end.getDate() + 14);
    setDates(['home_allFixtures', ...eachDayOfInterval({ start: new Date(), end })]);
  }, []);

  const renderDate = d => {
    const format = getRelativeLocaleDate(d);

    const isSelected = selectedDate === d;

    return (
      <button
        type="button"
        className={`${isSelected ? 'item_selected' : 'item'} uppercase`}
        style={{
          borderColor: isSelected && mainBrand ? mainBrand : undefined,
        }}
        key={format}
        onClick={() => {
          trackEvent(eventTypes.datePicker, apiKey);
          onPressDate(d);
        }}
      >
        {d === 'home_allFixtures' ? t(d) : format}
      </button>
    );
  };

  const ref = useRef(null);
  const scrollWidth = (ref?.current?.offsetWidth || 0) - 35;

  const scroll = scrollOffset => {
    ref.current.scrollLeft += scrollOffset;
  };

  const handleNavigation = useCallback(() => {
    const { scrollLeft, clientWidth, scrollWidth: fullElWidth } = ref?.current || {};

    const isScrolledLeft = scrollLeft === 0;
    const isScrolledRight = Math.ceil(scrollLeft + clientWidth) >= fullElWidth;

    setLeftDisabled(isScrolledLeft);
    setRightDisabled(isScrolledRight);
  }, []);

  useEffect(() => {
    window.addEventListener('load', handleNavigation);
    window.addEventListener('resize', handleNavigation);
    const currentRef = ref?.current;
    currentRef?.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('load', handleNavigation);
      window.removeEventListener('resize', handleNavigation);
      currentRef?.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  const buttonStyles = 'my-2 p-2.5 rounded-full focus:outline-none';

  return (
    <div className={`flex w-full items-center pb-6 ${containerClasses}`}>
      <button
        aria-label={t('dateSelector.goBack')}
        type="button"
        disabled={leftDisabled}
        className={`${leftDisabled ? 'bg-veryLightGrey' : 'bg-lightGrey'} mr-4 ${buttonStyles}`}
        onClick={() => scroll(-scrollWidth)}
      >
        <Chevron size={12} className={`${leftDisabled ? 'opacity-30' : 'text-black'} -rotate-90`} />
      </button>
      <div ref={ref} className="buttonsWrapper" data-test-id="date-selector">
        {dates.map(date => renderDate(date))}
      </div>
      <button
        aria-label={t('dateSelector.showMore')}
        type="button"
        disabled={rightDisabled}
        className={`${rightDisabled ? 'bg-veryLightGrey' : 'bg-lightGrey'} ml-4 ${buttonStyles}`}
        onClick={() => scroll(scrollWidth)}
      >
        <Chevron size={12} className={`${rightDisabled ? 'opacity-30' : 'text-black'} rotate-90`} />
      </button>
    </div>
  );
};

DateSelector.propTypes = {
  onPressDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  apiKey: PropTypes.string.isRequired,
  mainBrand: PropTypes.string,
  containerClasses: PropTypes.string.isRequired,
};

DateSelector.defaultProps = {
  mainBrand: null,
};

export default DateSelector;
